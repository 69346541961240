<template>
  <div>
    <h1 class="text-primary title-patternOne">
      Bem-vindo à Educacross! Acesso liberado.
    </h1>
    <h3 class="color-gray1 text-patternTwo">
      Selecione qual perfil deseja acessar no aplicativo.
    </h3>
    <b-row>
      <b-col cols="12" lg="6" class="d-flex align-items-stretch">
        <b-card
          @click="chooseProfile('responsible')"
          :class="{ 'bg-primaryOpacity': responsible.selected }"
        >
          <div class="d-flex">
            <img :src="responsible.image" alt="profile-responsible" />
            <div class="d-flex flex-column justify-content-center data-container">
              <p class="color-gray2 text-patternOne">
                Nome: <span class="color-gray1">{{ responsible.name }}</span>
              </p>
              <p class="color-gray2 text-patternOne">
                <span>
                  E-mail:
                  <span class="color-gray1"> {{ responsible.email }} </span>
                </span>
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" class="d-flex align-items-stretch">
        <b-card
          class="student-card"
          @click="chooseProfile('student')"
          :class="{ 'bg-primaryOpacity': student.selected }"
        >
          <div class="d-flex">
            <img
              :src="getImgProfileStudent(student.imageId)"
              alt="profile-student"
              :style="`background: ${student.background}`"
            />
            <div class="d-flex flex-column justify-content-center">
              <p class="color-gray2 text-patternOne">
                Nome: <span class="color-gray1">{{ student.name }}</span>
              </p>
              <p class="color-gray2 text-patternOne">
                <span>
                  Nome de usuário:
                  <span class="color-gray1"> {{ student.userName }} </span>
                </span>
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <error-message :error="feedbackGeneralError" />
    <b-row>
      <b-col>
        <b-button
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="loadingButton"
          @click="userLogin()"
          ><b-spinner large v-if="loadingButton"></b-spinner
          ><span v-else>Entrar</span></b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { COLOR_PALETTE } from '@/components/consts/const';

export default {
  data: () => ({
    loadingButton: false,
    student: {},
    responsible: {},
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    userLogin() {
      this.loadingButton = true;
      let payload = {
        token: this.student.selected
          ? this.student.token
          : this.responsible.token,
        isWebGl: JSON.parse(localStorage.getItem('is_webgl'))
      };
      this.$onboardingService
        .loginInApp(payload)
        .then((response) => {
          // location.replace(response.data.redirectUri);
          location.href = response.data.redirectUri;
          localStorage.clear();
        })
        .catch((error) => {
          this.feedbackGeneralError = error;
        });
      this.loadingButton = false;
    },
    getImgProfileStudent(id) {
      return require(`@/assets/images/profile/${id}.png`);
    },
    chooseProfile(param) {
      if (param == 'student') {
        this.responsible.selected = false;
        this.student.selected = true;
      } else if (param == 'responsible') {
        this.responsible.selected = true;
        this.student.selected = false;
      }
    },
    getUsersInfo() {
      let accountToken = localStorage.getItem('account_token');
      let accessToken = localStorage.getItem('access_token');

      let decodedAccountToken = this.$utilService.getDecodedToken(accountToken);
      let decodedAccessToken = this.$utilService.getDecodedToken(accessToken);

      this.responsible = {
        name: decodedAccountToken.Name,
        email: decodedAccountToken.Email,
        image: decodedAccountToken.ImageUrl,
        token: accountToken,
        selected: true,
      };
      this.student = {
        name: decodedAccessToken.Name,
        userName: decodedAccessToken.UserName,
        imageId: Math.floor(Math.random() * 8) + 1,
        background:
          COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)],
        token: accessToken,
        selected: false,
      };
    },
  },
  created() {
    this.getUsersInfo();
  },
};
</script>

<style lang="scss" scoped>
.data-container {
  width: 70%;
}

h1 {
  margin-bottom: 2rem;
}

.row {
  margin-top: 4rem;
  .card {
    text-align: left;
    cursor: pointer;
    border: 2px solid #c4c4c4;
    margin-bottom: 2rem;
    width: 100%;
    // height: 85%;
    .card-body {
      padding: 1.75rem;
    }

    &.bg-primaryOpacity {
      transition: all 0.8s ease;
      border-color: var(--primary);
    }

    img {
      width: 100px;
      height: 100px;
      margin: 0.5rem 1.5rem 0.5rem 0;
    }

    &.student-card {
      img {
        border-radius: 8px;
        padding: 12px;
      }
    }
  }
  .btn {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }
}

@media (max-width: 768px) {
  h1 {
    margin-bottom: 0.5rem;
  }
  .row {
    margin-top: 1.5rem;
    .card {
      margin-bottom: 1.5rem;
      .card-body {
        padding: 1.25rem;
      }
    }
    .btn {
      margin-top: 0rem;
      margin-bottom: 0.75rem;
    }
    &:last-child {
      margin-top: 0.75rem;
    }
  }
}
</style>