export const COLOR_PALETTE = [
    '#FFD389',
    '#D273FF',
    '#5AC4FF',
    '#6E64E9',
    '#E9934C',
    '#FE5153',
    '#00BDB8',
    '#7F6CC3',
    '#FFEA93',
    '#A1D946',
]